<template>
  <v-container
    id="support"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="headers"
      :items="dataFiltered"
      :search="search"
      sort-by="date"
      :sort-desc="true"
      :no-data-text="str['no_data']"
      :no-results-text="str['no_data']"
      class="elevation-1"
      @click:row="openDialog"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            v-if="editPermission"
            color="success"
            dark
            class="mb-2"
            @click="openDialog()"
          >
            {{ str['add'] }}
          </v-btn>
        </v-toolbar>
        <v-toolbar
          flat
          class="toolbar-adjust"
        >
          <v-row>
            <v-col
              cols="6"
            >
              <v-autocomplete
                v-model="employeeFilter"
                :label="str['employee']"
                item-text="name"
                item-value="id"
                :items="employees"
                :no-data-text="str['no_data']"
                hide-details
                clearable
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-autocomplete
                v-model="clientFilter"
                :label="str['client']"
                item-text="name"
                item-value="id"
                :items="clients"
                :no-data-text="str['no_data']"
                hide-details
                clearable
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.status="{ item }">
        <span
          v-if="statusDict[item.status]"
          v-bind:class="[statusDict[item.status] && statusDict[item.status].type ? (statusDict[item.status].type + '--text') : '']"
        >
          {{ statusDict[item.status].label }}
        </span>
      </template>
      <template v-slot:item.employee_id="{ item }">
        {{ employeesDict[item.employee_id] ? (item.employee_id + ' - ' + employeesDict[item.employee_id]) : item.employee_id }}
      </template>
      <template v-slot:item.client_id="{ item }">
        {{ clientsDict[item.client_id] ? (item.client_id + ' - ' + clientsDict[item.client_id]) : item.client_id }}
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogItem"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ itemToEdit ? str['edit_task'] : str['new_task'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-for="item in fields"
                :key="item.id"
                :cols="item.cols ? item.cols : 12"
              >
                <v-text-field
                  v-if="item.type === 'input'"
                  v-model="item.value"
                  :label="item.title + (item.required ? ' *' : '')"
                  hide-details
                />
                <v-textarea
                  v-if="item.type === 'textarea'"
                  v-model="item.value"
                  :label="item.title + (item.required ? ' *' : '')"
                  hide-details
                />
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :label="item.title + (item.required ? ' *' : '')"
                  :item-text="item.itemText ? item.itemText : 'label'"
                  :item-value="item.itemId ? item.itemId : 'value'"
                  :items="item.items"
                  :no-data-text="str['no_data']"
                  hide-details
                  :disabled="item.disabled"
                  :search-input.sync="item.searchInput"
                  @change="item.searchInput=''"
                />
                <v-menu
                  v-if="item.type === 'datepicker'"
                  v-model="item.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.value"
                      v-bind:label="item.title"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    />
                  </template>
                  <v-date-picker
                    v-model="item.value"
                    style="margin: 0;"
                    @change="item.menu = false"
                    :locale="datepickerLanguage"
                  />
                </v-menu>
                <v-menu
                  v-if="item.type === 'timepicker'"
                  v-model="item.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.value"
                      v-bind:label="item.title"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    />
                  </template>
                  <v-time-picker
                    v-model="item.value"
                    style="margin: 0;"
                    format="24hr"
                    @change="item.menu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeDialog()"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            v-if="itemToEdit"
            color="error"
            @click="deleteItem()"
          >
            {{ str['delete'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="saveItem()"
            :disabled="!fieldsValidated()"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    data () {
      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        user: Utils.getUser(),
        editPermission: Utils.hasPermission('task_edit'),
        data: [],
        search: '',
        headers: [
          { text: window.strings['id'], value: 'id', align: 'left', width: 60 },
          { text: window.strings['title'], value: 'title', align: 'center', width: 250 },
          { text: window.strings['date'], value: 'date_time', align: 'center', width: 150 },
          { text: window.strings['status'], value: 'status', align: 'center', width: 150 },
          { text: window.strings['employee_id'], value: 'employee_id', align: 'center', width: 150 },
          { text: window.strings['client_id'], value: 'client_id', align: 'center', width: 150 },
        ],
        fields: [{
          type: 'select',
          id: 'status',
          title: window.strings['status'],
          items: Utils.getTaskStatus(),
          value: '',
          searchInput: '',
          required: true,
          cols: 4,
        }, {
          type: 'datepicker',
          id: 'date',
          title: window.strings['date'],
          value: '',
          required: true,
          cols: 4,
        }, {
          type: 'timepicker',
          id: 'time',
          title: window.strings['hour'],
          value: '',
          required: true,
          cols: 4,
        }, {
          type: 'select',
          id: 'employee_id',
          title: window.strings['employee_id'],
          items: [],
          value: '',
          searchInput: '',
          required: true,
          itemId: 'id',
          itemText: 'name',
          cols: 6,
        }, {
          type: 'select',
          id: 'client_id',
          title: window.strings['client_id'],
          items: [],
          value: '',
          searchInput: '',
          required: true,
          itemId: 'id',
          itemText: 'name',
          cols: 6,
        }, {
          type: 'input',
          id: 'title',
          title: window.strings['title'],
          value: '',
          required: true,
        }, {
          type: 'textarea',
          id: 'info',
          title: window.strings['description'],
          value: '',
          required: false,
        }, {
          type: 'textarea',
          id: 'notes',
          title: window.strings['notes'],
          value: '',
          required: false,
        }],
        dialogItem: false,
        itemToEdit: null,
        userActiveStatus: 0,
        statusDict: this.getStatusDict(),
        employees: [],
        employeesDict: {},
        clients: [],
        clientsDict: {},
        clientFilter: null,
        employeeFilter: null,
      }
    },
    watch: {
      dialogItem (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }
        val || this.closeDialog()
      },
    },
    computed: {
      dataFiltered() {
        const self = this;
        let data = this.data
        if (this.employeeFilter !== null && this.employeeFilter !== '') {
          data = data.filter(function (item) {
            return item.employee_id === self.employeeFilter
          })
        }
        if (this.clientFilter !== null && this.clientFilter !== '') {
          data = data.filter(function (item) {
            return item.client_id === self.clientFilter
          })
        }
        return data
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.getList()
      this.getEmployees()
      this.getClients()
    },
    methods: {
      getStatusDict: function () {
        const items = Utils.getTaskStatus()
        const dict = {}
        if (items && items.length) {
          items.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getList: function () {
        const self = this
        this.$isLoading(true)
        Api.getTasks(function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.data = response.data
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getEmployees: function () {
        const self = this
        Api.getEmployees({
          fields: ['id', 'name']
        }, function (response) {
          if (response.success) {
            const dict = {}
            for (let i = 0; i < self.fields.length; i++) {
              if (self.fields[i].id === 'employee_id') {
                self.fields[i].items = response.data.map(a => ({id: a.id, name: a.id + ' - ' + a.name}))
                response.data.forEach(function (d) {
                  dict[d.id] = d.name
                })
                self.employees = self.fields[i].items
                break
              }
            }
            self.employeesDict = dict
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getClients: function () {
        const self = this
        Api.getClients({
          status: this.userActiveStatus,
          fields: ['id', 'name'],
          useCache: true
        }, function (response) {
          if (response.success) {
            const dict = {}
            for (let i = 0; i < self.fields.length; i++) {
              if (self.fields[i].id === 'client_id') {
                self.fields[i].items = response.data.map(a => ({id: a.id, name: a.id + ' - ' + a.name}))
                response.data.forEach(function (d) {
                  dict[d.id] = d.name
                })
                self.clients = self.fields[i].items
                break
              }
            }
            self.clientsDict = dict
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openDialog(item) {
        if (this.editPermission) {
          this.itemToEdit = item
          this.setData()
          this.dialogItem = true
        }
      },
      closeDialog() {
        this.dialogItem = false
        this.itemToEdit = null
      },
      fieldsValidated() {
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].required && !this.fields[i].value && this.fields[i].value !== 0) {
            return false
          }
        }
        return true
      },
      saveItem() {
        const self = this
        const data = this.getData()
        if (data) {
          this.$isLoading(true)
          if (this.itemToEdit) {
            data.id = this.itemToEdit.id
            Api.updateTask(data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeDialog()
                self.getList()
              } else {
                self.$alert(
                  response.message,
                  window.strings['warning'],
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            Api.newTask(data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeDialog()
                self.getList()
              } else {
                self.$alert(
                  response.message,
                  window.strings['warning'],
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }
        }
      },
      getData() {
        const data = {}
        const dateTimeObj = {}
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].required && !this.fields[i].value && this.fields[i].value !== 0) {
            return false
          }
          if (this.fields[i].id === 'date' || this.fields[i].id === 'time') {
            dateTimeObj[this.fields[i].id] = this.fields[i].value
          } else {
            data[this.fields[i].id] = this.fields[i].value
          }
        }
        data.date_time = dateTimeObj.date + ' ' + dateTimeObj.time
        return data
      },
      setData() {
        const dateTimeSplit = this.itemToEdit && this.itemToEdit.date_time ? this.itemToEdit.date_time.split(' ') : null
        const dateTimeObj = {
          date: dateTimeSplit && dateTimeSplit[0] ? dateTimeSplit[0] : '',
          time: dateTimeSplit && dateTimeSplit[1] ? dateTimeSplit[1] : ''
        }
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].id === 'date' || this.fields[i].id === 'time') {
            this.fields[i].value = dateTimeObj[this.fields[i].id]
          } else {
            this.fields[i].value = this.itemToEdit && (this.itemToEdit[this.fields[i].id] || this.itemToEdit[this.fields[i].id] === 0) ? this.itemToEdit[this.fields[i].id] : ''
          }
        }
      },
      deleteItem() {
        const self = this
        this.$confirm(
          window.strings['are_you_sure_delete'],
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteTask({
            id: this.itemToEdit.id
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeDialog()
              self.getList()
            } else {
              self.$alert(
                response.message,
                window.strings['warning'],
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
    },
  }
</script>
